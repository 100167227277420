<template>
  <div>
    <asideBar />
    <div class="main-content flex-fill" :style="{ 'margin-left': $store.state.isMobile ? '0px' : $store.state.collapse ? '200px' : '60px' }">
      <div class="container customize-width px-md-5 mt-4">
        <main class="content">
          <div class="content-wrap">
            <div class="content-layout">
              <nav class="text-sm text-muted mb-3 mb-md-4">
                <i class="iconfont icon-home mr-2"></i><a class="crumbs" href="/">首页</a><i class="px-2">/</i><span>网站排行榜</span>
              </nav>
              <div class="panel">
                <div class="panel-header text-center mb-4">
                  <h2 class="h2 font-weight-bold mb-3">100&nbsp;个顶级网站</h2>
                  <p class="text-muted">根据{{ siteName }}用户流量估计，全球访问量最高的前100个网站，每月更新。</p>
                </div>
              </div>
              <div class="card p-3 p-md-4" v-for="(item, index) in getDataList" :key="item.id" style="cursor:pointer">
                <div class="d-flex align-items-center" @click="goIndex('/details?id=' + item.id)">
                  <div style="flex: 0 0 50px" class="text-center mr-3 mr-md-4">
                    <div style="width: 100%; line-height: 1; height: auto;" class="text-xl font-weight-bold hot-rank hot-rank-2 mr-0">
                      {{ index + 1 }}
                    </div>
                    <!-- <a :href="item.href" class="d-block text-muted mt-2"
                  ><i class="io io-huore mr-1"></i><span>{{ item.count }}</span></a
                > -->
                  </div>
                  <div class="mr-3 mr-md-4">
                    <a><img class="lazy" :src="imageESrc + item.image" height="90" width="90"/></a>
                  </div>
                  <div>
                    <div class="mb-1 mb-md-2 overflowClip_1">
                      <a :href="item.href" class="h5 mb-0"
                        ><b>{{ item.title }}</b></a
                      >
                    </div>
                    <div class="desc text-muted text-sm overflowClip_2">
                      {{ item.content }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="posts-nav mb-3 mb-md-4">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="total"
                  @prev-click="prevClick"
                  @next-click="nextClick"
                  @current-change="handleCurrentChange"
                >
                </el-pagination>
              </div>
              <p class="text-center text-muted mt-md-5 mt-4 mb-md-4 mb-2">
                当前页面数据统计来自{{ siteName }}内部数据，根据网站流量计算网站热度，并不代表其网站真实流量数据，仅供参考。
              </p>
            </div>
          </div>
        </main>
      </div>
    </div>
    <!-- 弹框 -->
    <asideDrawer />
  </div>
</template>

<script>
import { getAllNav } from '@/api/http'
import { API_URL } from '@/utils/request.js'
import asideBar from '@/views/layout/components/aside'
import asideDrawer from '@/views/layout/components/asideDrawer'
export default {
  components: { asideBar, asideDrawer },
  data() {
    return {
      getDataList: [],
      imageESrc: API_URL,
      page: 1,
      total: 0,
      siteName: localStorage.getItem('siteName')
    }
  },

  created() {
    this.getList()
  },
  methods: {
    goIndex(val) {
      this.$router.push(val)
    },
    async getList() {
      const res = await getAllNav({ order: 'degree', page: this.page })
      this.getDataList = res.data.data.rows
      this.total = res.data.data.total
      console.log('-----res-------', JSON.stringify(res.data.data))
    },
    prevClick(val) {
      this.page = val
      this.getList()
      console.log('====prevClick=====', val)
    },
    nextClick(val) {
      this.page = val
      this.getList()
      console.log('====nextClick=====', val)
    },
    handleCurrentChange(val) {
      this.page = val
      this.getList()
      console.log(`当前页---: ${val}`)
    }
  }
}
</script>

<style>
@import url('../css/bootstrap.css');
@import url('../css/style.css');
</style>
